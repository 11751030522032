<template>
    <button
        :type="type"
        :class="['btn', variantClass, sizeClass, outlineClass]">
        <template v-if="icon && iconPosition === 'left'">
            <span
                :class="[iconClass, { filled: props.filled }]"
                class="material-symbols-outlined">
                {{ props.icon }}
            </span>
        </template>

        <slot></slot>

        <template v-if="icon && iconPosition === 'right'">
            <span class="material-symbols-outlined">{{ props.icon }}</span>
        </template>
    </button>
</template>

<script setup>
    import { computed } from "vue";

    const props = defineProps({
        icon: {
            type: String,
            default: null,
        },
        iconPosition: {
            type: String,
            default: "left",
        },
        iconClass: [String, Object],
        filled: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: "md",
        },
        type: {
            type: String,
            default: "button",
        },
        outline: {
            type: Boolean,
            default: false,
        },
        variant: {
            type: String,
            default: "red",
        },
    });

    const variantClass = computed(() => {
        switch (props.variant) {
            case "gray":
                return "variant-gray";
            case "success":
                return "variant-success";
            case "warning":
                return "variant-warning";
            case "info":
                return "variant-info";
            default:
                return "variant-red";
        }
    });

    const outlineClass = computed(() => (props.outline ? "btn-outline" : ""));

    // TODO: Проверить нужны ли для кнопок sizeClass и убрать ненужные варианты
    const sizeClass = computed(() => `size-${props.size}`);
</script>
